import React from 'react'
import './secondNav.css'

const Secondnav = () => {
  return (
    
          <nav className='navbars'>
              <h1 className='secondhead'>Dec 2024 🔈 : Seeking a full-time role in software development or data analysis  </h1>
          </nav>
  )
}

export default Secondnav