import React, { useState,useRef } from 'react';
import './navbar.css';
import logo from '../media/Dark_logo_main.png';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

const Navbar = () => {


  const [theme, setTheme] = useState('');
  const toggleTheme = () => {
    if (theme === '') {
      setTheme('light');
    } else {
      setTheme('');
    }
  };
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };




  return (
    <nav className={`navbar `} id='navbar'>
      <img src={logo} alt='Logo' className={`logo`} />

      <div className='menuIcon'>
    
      <input type="checkbox" id="checkbox4" className={`checkbox4 visuallyHidden  ${isMobileMenuOpen ? 'menuIconActive' : ''}`} onClick={toggleMobileMenu}></input>
        <label htmlFor="checkbox4">
            <div className="hamburger hamburger4">
                <span className="bar bar1"></span>
                <span className="bar bar2"></span>
                <span className="bar bar3"></span>
                <span className="bar bar4"></span>
                <span className="bar bar5"></span>
            </div>
        </label>
      </div>



     

      <div className='buttonContainer'>  
        <div className='desktopMenu'>
          <Link to='navbars' spy={true} smooth={true} duration={500} className='desktopMenuListItem'>home</Link>
          <Link to='aboutMain' spy={true} smooth={true} duration={500}  className='desktopMenuListItem'>about</Link>
          <Link to='expMain' spy={true} smooth={true} duration={500} className='desktopMenuListItem'>experience</Link>
          <Link to='projectMain' spy={true} smooth={true} duration={500} className='desktopMenuListItem'>projects</Link>
          <Link to='skillsMain' spy={true} smooth={true} duration={500} className='desktopMenuListItem'>skills</Link>
        </div>      

        <div className="desktopMenuBtn" >
        <i className="fas fa-user desktopMenuImg"><Link to='footMain' spy={true} smooth={true} duration={500} className='desktopMenuListItem'>Get in touch</Link></i>
        
      </div>

        
      </div>

      <div className={`mobileMenu ${isMobileMenuOpen ? 'show' : ''}`}>
      <Link to='navbars' spy={true} smooth={true} duration={500} className='desktopMenuListItem' onClick={toggleMobileMenu}>home</Link>
          <Link to='aboutMain' spy={true} smooth={true} duration={500}  className='desktopMenuListItem' onClick={toggleMobileMenu}>about</Link>
          <Link to='expMain' spy={true} smooth={true} duration={500} className='desktopMenuListItem' onClick={toggleMobileMenu}>experience</Link>
          <Link to='projectMain' spy={true} smooth={true} duration={500} className='desktopMenuListItem' onClick={toggleMobileMenu}>projects</Link>
          <Link to='skillsMain' spy={true} smooth={true} duration={500} className='desktopMenuListItem' onClick={toggleMobileMenu}>skills</Link>
        <Link to='footMain' className='mobileMenuListItem' onClick={toggleMobileMenu}>
          <i className="fas fa-user desktopMenuImg"></i>
          Get in Touch
        </Link>

        {/* <div className='socials'>
        <Link className='mobileMenuListItem' onClick={toggleMobileMenu}><FontAwesomeIcon className='soc' icon={faLinkedin} /></Link>
        <Link className='mobileMenuListItem' onClick={toggleMobileMenu}><FontAwesomeIcon className='soc' icon={faGithub} /></Link>
        <Link className='mobileMenuListItem' onClick={toggleMobileMenu}><FontAwesomeIcon className='soc' icon={faInstagram} /></Link>
        <Link className='mobileMenuListItem' onClick={toggleMobileMenu}><FontAwesomeIcon className='soc' icon={faTwitter} /></Link>
        </div> */}
        
      </div>
    </nav>
  );
}

export default Navbar;
