import React, { useEffect , useState } from 'react';
import './Analysis.css';
import unc from '../../media/uncc.webp';
import sinhgad from '../../media/sinhgad.png';
import da1 from '../../media/da1.jpeg';
import da2 from '../../media/da2.jpeg';
import da3 from '../../media/da3.jpeg';
import $ from 'jquery'; // Import jQuerynpm
import 'tilt.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';


const Analysis = () => {

  


 

  return (
    <div className='DataMain'>
       <div className='headData'>Data Analysis - Visualizations</div>
       <div className="cardContainer">
       <div className="frame1">
  <a 
    href="https://public.tableau.com/views/WorldMilitary2/Story1?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <span className="cardTitle">World Military</span>
    <div className="iframe1">
      <img src={da1} alt="Tata Consultancy Services" />
    </div>
  </a>
</div>

  <div className="frame1">
  <a 
    href="https://public.tableau.com/views/MeclenburgCounty/Dashboard1?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <span className="cardTitle">Charlotte - Meclenburg County</span>
    <div className="iframe1"><img src={da2} alt='Tata Consultancy Services' /></div>
    </a>
  </div>
  <div className="frame1">
  <a 
    href="https://public.tableau.com/views/VAGroupProject-Group3-Nutritionists-Dashboard2_17016277467540/Story1?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <span className="cardTitle">Metabo Foods</span>
    <div className="iframe1"><img src={da3} alt='Tata Consultancy Services' /></div>
    </a>
  </div>
</div>
       

     
    </div>
  );
}

export default Analysis;
