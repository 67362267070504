import React from 'react';
import './experience.css';
import whitecap from '../../media/exp1Wc.jpeg';
import reb from '../../media/exp4rf.png';
import uncc from '../../media/exp2uncc.png';
import tcs from '../../media/exp3tcs.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons'; 

const Experience = () => {

  


  const [isVisible, setIsVisible] = React.useState(false);
  const domRef = React.useRef();

  React.useEffect(() => {
      const currentRef = domRef.current;
      const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => setIsVisible(entry.isIntersecting));
      });
      observer.observe(currentRef);
      return () => observer.unobserve(currentRef);
  }, []);


  return (
    <div className='expMain'>
       <div className='vline'></div>
       
      <div className='timeline'>

      <div className="timeline-item">
        
        <div className='timeline-img'>
          <img src={reb} alt='White Cap' />
          <div className='hline'>   <FontAwesomeIcon className='circle' icon={faCircle} style={{color: "#ffd460",}} /></div>
       
        </div>
        <div className='timeline-content'>
        <span className='expHead'>Experiences<div className='eduLine'></div></span>
    
          <span className='date'>MAY 2023 - AUG 2023</span>
          <span className='CompanyName'>Rebbecca Everlene Foundation  <span className='curr'>Current</span></span>
          <span className='role'><span className='curr'>Part Time</span>  Software Developer</span>
          <span className='desc'>
          Designed and implemented complex business modules and API-driven solutions in C#/.NET and Python for a healthcare platform, improving system scalability and usability.
Refactored and maintained ReactJS-based front-end applications using JavaScript and Python, optimizing performance and ensuring adherence to security best practices.
          </span>
        </div>
      </div>  
    
        <div className="timeline-item">
        
          <div className='timeline-img'>
            <img src={whitecap} alt='White Cap' />
            <div className='hline'>   <FontAwesomeIcon className='circle' icon={faCircle} style={{color: "#ffd460",}} /></div>
         
          </div>
          <div className='timeline-content'>
         
      
            <span className='date'>MAY 2023 - AUG 2023</span>
            <span className='CompanyName'>White Cap</span>
            <span className='role'>Programming Analyst</span>
            <span className='desc'>
              Successfully integrated the Whitecaps Cheakit 3.0 application with Power BI, a process involving the creation of dedicated workspaces and customized reports within the Power BI environment. Leveraging the power of React.js, this integration significantly improved data visualization and analytical capabilities, providing users with enhanced insights and enabling more effective decision-making.
            </span>
          </div>
        </div>

        <div className={`timeline-item ${isVisible ? 'fade-inb' : ''}`} ref={domRef}>
          <div className='timeline-img'>
            <img src={uncc} alt='University of North Carolina at Charlotte' />
            <div className='hline'> <FontAwesomeIcon className='circle' icon={faCircle} style={{color: "#ffd460",}} /></div>
          </div>

       
          <div className='timeline-content'>
            <span className='date'>AUG 2023 - May 2024</span>
            <span className='CompanyName'>University of North Carolina at Charlotte</span>
            <span className='role'>Graduate Assistant - Programming Languages </span>
            <span className='desc'>
              Graduate Assistant for the Survey of Programming Languages course, where I delve into the intricacies of various languages. This includes syntax disparities, error handling, coding style, compilation methods, time/space complexity, and code optimization techniques.
            </span>
          </div>
        </div>

        <div className={`timeline-item ${isVisible ? 'fade-inc' : ''}`} ref={domRef}>
          <div className='timeline-img'>
            <img src={uncc} alt='University of North Carolina at Charlotte' />
            <div className='hline'> <FontAwesomeIcon className='circle' icon={faCircle} style={{color: "#ffd460",}} /></div>
          </div>

       
          <div className='timeline-content'>
            <span className='date'>JAN 2023 - MAY 2023</span>
            <span className='CompanyName'>University of North Carolina at Charlotte</span>
            <span className='role'>Research Assistant - Robotics</span>
            <span className='desc'>
            Conducted research in robotics at the University of North Carolina at Charlotte, focusing on Python-based AI and machine learning algorithms for robotic automation, path planning, and sensor data processing            </span>
          </div>
        </div>

        <div className={`timeline-item ${isVisible ? 'fade-ind' : ''}`} ref={domRef}>
          <div className='timeline-img'>
            <img src={tcs} alt='Tata Consultancy Services' />
            <div className='hline'> <FontAwesomeIcon className='circle' icon={faCircle} style={{color: "#ffd460",}} /></div>
          </div>
          <div className='timeline-content'>
            <span className='date'>AUG 2019 - MAY 2023</span>
            <span className='CompanyName'>Tata Consultancy Services</span>
            <span className='role'>Software Engineer (SDE)</span>
            <span className='desc'>
            Collaborated on feature development for CreditShift, a high-transaction credit card app, improving user engagement by 20% through enhanced UI/UX with JavaScript, React, and Vue. Designed and integrated secure RESTful APIs with SQL and NoSQL databases for reliable data flow. Built and maintained CI/CD pipelines with Jenkins, enabling efficient deployments and seamless version control. Developed scalable backend microservices using Node.js and GoLang, optimizing performance for handling 250 million daily transactions.            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
