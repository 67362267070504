import React from 'react'
import './about.css'
import logo from '../../media/bgimg@1.5x.png'
import photo from '../../media/filephoto.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Fade } from 'react-awesome-reveal';

const About = () => {

    const [isVisible, setIsVisible] = React.useState(false);
    const domRef = React.useRef();

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => setIsVisible(entry.isIntersecting));
            },
            { rootMargin: '0px 0px -50px 0px' } // Adjust the margin to control when visibility is triggered
        );
        observer.observe(domRef.current);
        return () => observer.disconnect();
    }, []);
    
    return (
        <div 
        className={`aboutMain`} 
        ref={domRef}
    >
        <div className='aboutMain'>
            <div className='aboutLeft '>
           
                    <div className="backgroundLogo">
                        <img src={logo} alt="Logo"></img>
                    </div>
                    <div className="foregroundPhoto">
                        <img src={photo} alt="Photo"></img>
                    </div>
                
            </div>
            <div className='aboutRight'>
            
                    <span className='aboutRightHeader'>About me<div className='line'></div></span>
                    <span className='aboutRightContentOne'>
                        Greetings! I'm Yash. I specialize in developing full-stack web applications that stand out for their <span className='aboutKeywordsOne'> efficiency, user-centric design </span> and <span className='aboutKeywordsOne'> visual appeal. </span>
                    </span>
                    <span className='aboutRightContentTwo'>
                        Boasting 3+ in full-stack development, I've recently delved into Machine Learning during my studies at UNC Charlotte. I am Committed to producing code that's <span className='aboutKeywordsOne'> streamlined, component-based, </span> and <span className='aboutKeywordsOne'> thoroughly vetted,</span> my aim is to create solutions that are not only <span className='aboutKeywordsOne'> efficient </span> and <span className='aboutKeywordsOne'> scalable </span> but also tailored to fit the ever-changing requirements of modern businesses.
                    </span>
                    <span className='aboutRightContentThree'>
                        I'm continually seeking new horizons and opportunities in software development, anticipating the <span className='aboutKeywordsOne'> innovations </span> the future will bring.
                    </span>
                    <button className='aboutLinkedin' onClick={() => window.open('https://www.linkedin.com/in/yahire/')}>
    <FontAwesomeIcon className='linkedinLogo' icon={faLinkedin} />Linkedin
</button>


               
         
            </div>
        </div>
        </div>
    )
}

export default About;
