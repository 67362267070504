import React, { useState , useRef , useEffect } from 'react'
import './foot.css'
import ChatBot from 'react-simple-chatbot';
import emailjs from '@emailjs/browser';
import botImg from '../../media/Dark_logo_main.png'
import port1 from '../../media/port1.png'
import port2 from '../../media/port2.png'
import {faLinkedin, faXTwitter} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faTwitter} from '@fortawesome/free-brands-svg-icons';
import {faInstagram} from '@fortawesome/free-brands-svg-icons';
import {faGithub} from '@fortawesome/free-brands-svg-icons';







  
const Foot = () => {


const [showChatBot, setShowChatBot] = useState(false);

useEffect(() => {
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const fullHeight = document.documentElement.scrollHeight;

    if (scrollTop + windowHeight >= fullHeight) {
      setShowChatBot(true);
    }
  };

  window.addEventListener('scroll', handleScroll);

  // Cleanup on component unmount
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);



    const customBubbleStyle = {
        // Add your custom styles here
        background: '#1E2C40', // Example background color
        color: 'white',       // Example text color
        fontFamily: 'Plus Jakarta Sans, sans-serif', // Example font family
        // Add any other styles you want to customize
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', // Fixed boxShadow property
        borderRadius:'10px'
      };

      const customBubbleStyle1 = {
        // Add your custom styles here
        background: '#446492', // Example background color
        color: 'white',       // Example text color
        fontFamily: 'Plus Jakarta Sans, sans-serif', // Example font family
        // Add any other styles you want to customize
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', // Fixed boxShadow property
        borderRadius:'10px'
      };

      const customStyle = {
        // Add your custom styles here
        background: '#2D4059', 
        paddingTop:'10px',
       
        color: 'white',       // Example text color
        fontFamily: 'Plus Jakarta Sans, sans-serif', // Example font family
        // Add any other styles you want to customize
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', // Fixed boxShadow property
      
   
      };

    const customBubbleInput = {
        // Add your custom styles here
        background: '#1E2C40', // Example background color
        color: 'white',       // Example text color
        fontFamily: 'Plus Jakarta Sans, sans-serif', // Example font family
        // Add any other styles you want to customize
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', // Fixed boxShadow property
        borderRadius:'10px',
        width:'280px',
        margin:'20px',
        border:'none'
     

      };

      const customBubbleInput1 = {
        // Add your custom styles here
        background: '#1E2C40', // Example background color
        color: 'white',       // Example text color
        fontFamily: 'Plus Jakarta Sans, sans-serif', // Example font family
        // Add any other styles you want to customize
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', // Fixed boxShadow property
        borderRadius:'10px',
        width:'260px',
       
        top:'-20px',
        position:'relative',
        border:'none',
        left:'20px'
     

      };

      const customSubmit = {
        // Add your custom styles here
        background: '#1E2C40', // Example background color
        color: 'white',       // Example text color
        fontFamily: 'Plus Jakarta Sans, sans-serif', // Example font family
        // Add any other styles you want to customize
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', // Fixed boxShadow property
        borderRadius:'10px',
        width:'50px',
        top:'-18px',
    
        left:'290px',
        
        border:'none'
     

      };

      const customHeightStyle = {
        // Add your custom styles here
        background: '#2D4059', // Example background color
        color: 'white',       // Example text color
        fontFamily: 'Plus Jakarta Sans, sans-serif', // Example font family
        // Add any other styles you want to customize
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', // Fixed boxShadow property
        padding: '40px', // Padding value as a string
        borderRadius:'10px',


      };
      
      

      const customBubbleOption = {
        // Add your custom styles here
        background: '#2D4059', // Example background color
        color: 'white',       // Example text color
        fontFamily: 'Plus Jakarta Sans, sans-serif' // Example font family
        // Add any other styles you want to customize
      };

    
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
      alert('Message send Succesfully')
  
      emailjs.sendForm('service_76habmk', 'template_cs1uxdq', form.current, 'qQEmMbff6ujlyJ5xb')
        .then((result) => {
            console.log(result.text);
           
        }, (error) => {
            console.log(error.text);
        });
    };
    const steps = [
        {
          id: "Greet",        
          message: "Hello, My Name is Yash , You can use this chatbot to know more about me",        
          trigger: "Done",        
        },        
        {       
          id: "Done",        
          message: "Please enter your name!",        
          trigger: "waiting1",        
        },      
        {      
          id: "waiting1",      
          user: true,      
          trigger: "Name",      
        },      
        {       
          id: "Name",     
          message: "Hi {previousValue}, where should we connect?",     
          trigger: "con",     
        },      
        {      
          id: "con",   
          options: [   
            {    
              value: "send",   
              label: "Send me a message",        
              trigger: "send",       
            },       
            { value: "social", label: "Social links", trigger: "social" },
                 
          ],       
        },       
        {       
          id: "send",       
              
          options:[
            {    
                value: "mail",   
                label: "Email",        
                trigger: "mail",       
              },       
              { value: "linked", label: "Linkedin", trigger: "linked" },
              { value: "here", label: "Continue Here", trigger: "here" },
          ]       
        },    
        
        
        {       
          id: "social",       
                 
            options:[
                {    
                    value: "insta",   
                    label: "Instagram",        
                    trigger: "insta",       
                  },       
                  { value: "twit", label: "Twitter", trigger: "twit" },
                  { value: "git", label: "Github", trigger: "git" },
              ]            
        }, 

        {       
            id: "mail",     
            component: (
                <div className='linkedFoot'> 
                <FontAwesomeIcon  icon={faEnvelope} />
                <button className='socialb' onClick={() => window.open('mailto:yashmahire@gmail.com')}>
    Email Me
</button>
                </div>
               
              ),      
            trigger: "over",    
          },  
          
          {       
            id: "linked",     
            component: (
                <div className='linkedFoot'> 
                <FontAwesomeIcon  icon={faLinkedin} />
                <button className='socialb' onClick={() => window.open('https://www.linkedin.com/in/yahire/')}>Go to Linkedin</button>
                </div>
               
              ),     
            trigger: 'over',     
          },   

          {       
            id: "here",     
            component: (
                <div> <form  ref={form} onSubmit={sendEmail}>
             
                <input className='field' placeholder='Name' type="text" name="user_name" />
              
                <input className='field' placeholder='Email' type="email" name="user_email" />
            
                <textarea placeholder='Your Message' name="message" />
                <input type="submit" value="Send" />
              </form> </div>
              ),     
            trigger: "over",     
          },  

          {       
            id: "insta",     
            component: (
                <div className='linkedFoot'> 
                <FontAwesomeIcon  icon={faInstagram} />
                <span>Go to Instagram</span>
                </div>
               
              ),       
            trigger: "over",     
          },   

          {       
            id: "twit",     
            component: (
                <div className='linkedFoot'> 
                <FontAwesomeIcon  icon={faTwitter} />
                <span>Go to Twitter</span>
                </div>
               
              ),       
            trigger: "over",     
          },   

          {       
            id: "git",     
            component: (
                <div className='linkedFoot'> 
                <FontAwesomeIcon  icon={faGithub} />
                <button className='socialb' onClick={() => window.open('https://github.com/YashAnetz')}>Go to Github</button>
                </div>
               
              ),       
            trigger: "over",     
          }, 

          {       
            id: "over",     
            options:[
                {    
                    value: "start",   
                    label: "Start Over",        
                    trigger: "Greet",       
                  },       
                  { value: "end", label: "End Chat", trigger: "end" },
              ]     
          }, 
          
          {       
            id: "end",     
            message: "Thanks",     
            end: true,     
          }, 
        
        
        




      ]; 
      
  const [music, setMusic] = useState('track/0yc6Gst2xkRu0eMLeRMGCX?utm_source=generator&theme=0')

  const musicArray = [
    'track/1Fid2jjqsHViMX6xNH70hE?utm_source=generator',
    'track/3AJwUDP919kvQ9QcozQPxg?utm_source=generator',
    'track/6lanRgr6wXibZr8KgzXxBl?utm_source=generator',
    'track/3DamFFqW32WihKkTVlwTYQ?utm_source=generator',
    'track/3xKsf9qdS1CyvXSMEid6g8?utm_source=generator',
    'track/0yc6Gst2xkRu0eMLeRMGCX?utm_source=generator&theme=0',
    'track/0ll8uFnc0nANY35E0Lfxvg?utm_source=generator',
    'playlist/378YjfhZd5xNV8hbErWglR?utm_source=generator'

  ]

  const changeMusic = (index) => {
    setMusic(musicArray[index])
  }

  

  return (
    <div className='footMain'>
      <div className='footElements'>
        <div className='footEl'>
          <iframe className='music' src={`https://open.spotify.com/embed/${music}`} frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
          <div className='changeMus'>
            <button onClick={() => changeMusic(0)}></button>
            <button onClick={() => changeMusic(1)}></button>
            <button onClick={() => changeMusic(2)}></button>
            <button onClick={() => changeMusic(3)}></button>
            <button onClick={() => changeMusic(4)}></button>
            <button onClick={() => changeMusic(5)}></button>
            <button onClick={() => changeMusic(6)}></button>
            <button onClick={() => changeMusic(7)}></button>
          </div>
        </div>
        <div className='footEl'>
        <div className='secOne'>
            
          
        <h2>Contact Me</h2>
              </div>
          <div className='secZero'>
            
          <a href="https://www.linkedin.com/in/yahire/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedin} size="2x"  />
      </a>

      {/* GitHub */}
      <a href="https://github.com/YashAnetz" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faGithub} size="2x"  />
      </a>

      {/* Instagram */}
      <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} size="2x"  />
      </a>

      {/* Twitter */}
      <a href="https://x.com/?lang=en&mx=2" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faXTwitter} size="2x"  />
      </a>
          </div>
          <div className='secTwo'>
     
       <FontAwesomeIcon  icon={faEnvelope} size="3x" />
     
       <span>yashmahire@gmail.com</span>
          </div>
          <div className='secThree'>
          <span>Site Views</span>
          <div className="counter-widget">
      <a
        href="https://www.freecounterstat.com"
        title="free hit counter"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://counter6.optistats.ovh/private/freecounterstat.php?c=pk1qbbgbqlxfnre5uc4alfhmr42fmfa4"
          alt="free hit counter"
          style={{ border: 0 }}
        />
      </a>
    </div>
          </div>
         
        </div>
        <div className='footEl'>
          
       
         
    {showChatBot && (
      <ChatBot
        steps={steps}
       
        headerTitle='Contact me'
        bubbleStyle={customBubbleStyle}
        bubbleOptionStyle={customBubbleStyle}
        className='chat'
        contentStyle={customBubbleOption}
        customStyle={customStyle}
        headerStyle={customHeightStyle}
        footerStyle={customBubbleOption}
        style={customStyle}
        inputStyle={customBubbleInput}
        headerComponent='Contact me'
        hideHeader='true'
        height='400px' // Adjusted height
      />
    )}
  </div>
       
 </div>

   
    </div>
  )
}

export default Foot
