import React from 'react'
import './footer.css'



const Footer = () => {
  return (
    <div className='footerMain'>
        <span>Designed and Developed by <span className='yash'>Yash Ahire</span> </span>
        </div>
  )
}

export default Footer