import './app.css';
import React, { useEffect, useState } from 'react';
import logo from '../src/media/Dark_logo_main.png'; 


import Navbar from "./components/navbar";
import Secondnav from "./components/secondNav";
import Intro from "./components/Intro/intro";
import About from "./components/About/about";
import Experience from './components/Experience/Experience';
import Projects from './components/Projects/projects';
import Skills from './components/Skills/skills';
import Analysis from './components/Analysis/Analysis';
import Education from './components/Education/education';
import Foot from './components/Foot/Foot';
import Footer from './components/footer/Footer';

 // Import CSS for splash screen

const SplashScreen = () => {
  return (
    <div
  style={{
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#2D4059",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    boxSizing: "border-box",
    overflow:"hidden",

  }}
>
  <img
    src={logo}
    alt="Logo"
    style={{
      width: "100px", // Reduce image size
      height: "auto", // Maintain aspect ratio
      marginBottom: "20px", // Optional spacing below the image
    }}
  />
</div>

  );
};

function App() {
  const [isSplashVisible, setIsSplashVisible] = useState(true);

  useEffect(() => {
    // Hide splash screen after 3 seconds
    const timer = setTimeout(() => {
      setIsSplashVisible(false);
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  useEffect(() => {
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  }, []);

  return (
    <div className="App">
      {/* Show splash screen if visible */}
      {isSplashVisible ? (
        <SplashScreen />
      ) : (
        <>
          <Navbar />
          <Secondnav />
          <Intro />
          <About />
          <Experience />
          <Projects />
          <Skills />
          <Analysis />
          <Education />
          <Foot />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
