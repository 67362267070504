import React, { useEffect , useState } from 'react';
import './projects.css';
import feature from '../../media/feature1.png';
import feature2 from '../../media/feature2.png';
import $ from 'jquery'; // Import jQuerynpm
import 'tilt.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import p1colorgen from '../../media/colorgen.jpeg'
import p2steps from '../../media/steps.JPEG'
import p3hear from '../../media/hearme.JPEG'
import p4todo from '../../media/todo.JPEG'
import p5wack from '../../media/wack.jpeg'
import p6budget from '../../media/budget.JPEG'
import p7guess from '../../media/guess.jpeg'
import p8bank from '../../media/bank.jpeg'



const Projects = () => {

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [showModal6, setShowModal6] = useState(false);
  const [showModal7, setShowModal7] = useState(false);
  const [showModal8, setShowModal8] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  const openModal2 = () => {
    setShowModal2(true);
  };

  const closeModal2 = () => {
    setShowModal2(false);
  };


  const openModal3 = () => {
    setShowModal3(true);
  };

  const closeModal3 = () => {
    setShowModal3(false);
  };


  const openModal4 = () => {
    setShowModal4(true);
  };

  const closeModal4 = () => {
    setShowModal4(false);
  };


  const openModal5 = () => {
    setShowModal5(true);
  };

  const closeModal5 = () => {
    setShowModal5(false);
  };


  const openModal6 = () => {
    setShowModal6(true);
  };

  const closeModal6 = () => {
    setShowModal6(false);
  };


  const openModal7 = () => {
    setShowModal7(true);
  };

  const closeModal7 = () => {
    setShowModal7(false);
  };


  const openModal8 = () => {
    setShowModal8(true);
  };

  const closeModal8 = () => {
    setShowModal8 (false);
  };




  useEffect(() => {
    // JavaScript code for cursor animation
    
  
    // Tilt effect using jQuery
    $(".card").tilt({
      maxTilt: 15,
      perspective: 1500,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      speed: 500,
      glare: false,
      maxGlare: 0.2,
      scale: 1.01
    });

    $(".other").tilt({
      maxTilt: 15,
      perspective: 1500,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      speed: 500,
      glare: false,
      maxGlare: 0.2,
      scale: 1.01
    });

  }, []);

  return (
    <div className='projectMain'>
      <div className='headProj'>Things I've Built<div className='eLine p'></div></div>
      <div className='featured'>
        <div className='projectFirst'>
          <div className="bg__gradient"></div>
          <div className="card">
            <img className='imgOne' src={feature} alt='Project Image' />
          </div>
          <div className='projectFirstInfo'>
            <div className='infoContent'>
              <span className='featuredHead'>Featured Project</span>
              <span className='projHead'>⚽ LivScore 2023</span>
              <span className='infoContentChild'>LiveScoreAPI, a React.js-based web app, delivers real-time football data. It integrates with 20+ leagues, providing standings, league names, and trending videos. With a 15-year data archive, it's a vital resource for enthusiasts. Modern React Hooks streamline state management and lifecycles. The user-friendly interface suits all tech levels. Securely hosted on Netlify, it ensures swift access. Ideal for football fans, researchers, and enthusiasts, LiveScoreAPI blends real-time and historical data from reliable APIs.
</span>
            </div>
            
            <div className='tech'>
              
              <span>react</span>
              <span>nodeJs</span>
              <span>restApi</span>
              <span>jquery</span>
              <FontAwesomeIcon className='ico' icon={faGithub} style={{ color: '#ffd460' }} onClick={() => window.open('https://github.com/YashAnetz/ ')}/>
              <FontAwesomeIcon className='ico' icon={faChevronCircleRight} style={{color: "#ffd460",}} onClick={() => window.open('https://livscoreapi.netlify.app/')} />


            </div>
            
          </div>
        </div>

        <div className='projectFirst'>
          
          <div className='projectFirstInfo'>
            <div className='infoContent1'>
              <span className='featuredHead'>Featured Project</span>
              <span className='projHead'>MiniBlogger 💬</span>
              <span className='infoContentChild'>Mini Blogger, a JavaScript-powered web app, acts as your anonymous diary and real-time blogging companion. It securely captures DOM data from webpages, storing it in Firebase for effortless blog creation and manipulation. With a focus on privacy, Mini Blogger offers anonymous expression. It provides real-time DOM object handling, simplifies data transfer to Firebase, and integrates blogs seamlessly. The user-friendly interface suits bloggers of all levels, ensuring an up-to-date diary. </span>
            </div>

            <div className='tech'>
              <span>firebase</span>
              <span>html</span>
              <span>css</span>
              <span>javascript</span>
              <FontAwesomeIcon className='ico' icon={faGithub} style={{ color: '#ffd460' }} onClick={() => window.open('https://github.com/YashAnetz/ ')} />
              <FontAwesomeIcon className='ico' icon={faChevronCircleRight} style={{color: "#ffd460",}} onClick={() => window.open('https://yashnetz.netlify.app/pages/blogdynamic')}/>
             

            </div>
          </div>
          <div className="bg__gradient"></div>
          <div className="card">
            <img className='imgOne' src={feature2} alt='Project Image' />
          </div>
        </div>


        
      </div>
      <div className='otherHead'>Mini Projects<div className='eLine mp'></div></div>
      <div className='otherProjects'>
      
        <div className='other' onClick={openModal}><img src={p1colorgen} ></img></div>
        <div className='other' onClick={openModal2}> <img src={p2steps} ></img></div>
        <div className='other' onClick={openModal3}> <img src={p3hear} ></img></div>
        <div className='other' onClick={openModal4}> <img src={p4todo} ></img></div>
        <div className='other' onClick={openModal5}> <img src={p5wack} ></img></div>
        <div className='other' onClick={openModal6}> <img src={p6budget} ></img></div>
        <div className='other' onClick={openModal7}><img src={p7guess} ></img></div>
        <div className='other' onClick={openModal8}><img src={p8bank} ></img></div>

        {showModal && (
        <div className='modal'>
   
          <button className='modal-close' onClick={closeModal}>
            &times; {/* "x" symbol for the close button */}
          
          </button>
          <span className='modalHead'>Color Generator</span>
          <div className='modalar'>
          <div className='modal-content'>
            <iframe src="https://yashnetz.netlify.app/pages/random.html"  allowfullscreen></iframe>
            
            <span>ColorGen is a JavaScript tool that generates random colors, captivating gradients, and gradient button styles. Developers can easily obtain hex and RGB codes for direct integration into their projects. Streamline your design workflow and enhance your code with vibrant and eye-catching elements, all at your fingertips.
</span>
            
          </div>
          <div className='bandTech'>
            <span>Javascript</span>
              <span>html</span>
              <span>css</span>
              
              <FontAwesomeIcon className='ico' icon={faGithub} style={{ color: '#ffd460' }} />
              <FontAwesomeIcon className='ico' icon={faChevronCircleRight} style={{color: "#ffd460",}} />
            </div>
            </div>
         
        </div>
      )}


{showModal2 && (
        <div className='modal'>
          <button className='modal-close' onClick={closeModal2}>
            &times; {/* "x" symbol for the close button */}
          </button>
          <span className='modalHead'>Walking Tracker</span>

          <div className='modalar'>
          <div className='modal-content'>
          <iframe src="https://yashnetz.netlify.app/pages/tracker"></iframe>
            <span>Introducing a JavaScript fitness app that diligently counts your daily steps, calculates calories expended, and monitors your progress over seven days. Users have the freedom to establish personal fitness objectives and will receive encouraging prompts upon achieving them, making it an indispensable tool for a healthier lifestyle. </span>
            
          </div>
          <div className='bandTech'>
            <span>Javascript</span>
              <span>html</span>
              <span>css</span>
              
              <FontAwesomeIcon className='ico' icon={faGithub} style={{ color: '#ffd460' }} />
              <FontAwesomeIcon className='ico' icon={faChevronCircleRight} style={{color: "#ffd460",}} />
            </div>
            </div>
         
        </div>
      )}

{showModal3 && (
        <div className='modal'>
          <button className='modal-close' onClick={closeModal3}>
            &times; {/* "x" symbol for the close button */}
          </button>
          <span className='modalHead'>Hear Me</span>

          <div className='modalar'>
          <div className='modal-content'>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/J1H-A5mk0lQ?si=dj5MY4GIC2XLAEA-" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <span>

Presenting a Python application empowered by the Leap Motion controller, incorporating machine learning for model training and testing. It effectively maps hand movements in the XYZ axis and translates sign language gestures into both audio and text, offering a vital means of communication for the hearing-impaired, bridging the gap and fostering inclusive interactions.</span>
            
          </div>
          <div className='bandTech'>
            <span>Javascript</span>
              <span>html</span>
              <span>css</span>
              
              <FontAwesomeIcon className='ico' icon={faGithub} style={{ color: '#ffd460' }} />
              <FontAwesomeIcon className='ico' icon={faChevronCircleRight} style={{color: "#ffd460",}} />
            </div>
            </div>
         
        </div>
      )}

{showModal4 && (
        <div className='modal'>
          <button className='modal-close' onClick={closeModal4}>
       

            &times; {/* "x" symbol for the close button */}
          </button>
          <span className='modalHead'>Planner</span>
          <div className='modalar'>
          <div className='modal-content'>
          <iframe src="https://yashnetz.netlify.app/pages/todo.html"></iframe>
            <span>Planner is a JavaScript application, your ultimate task manager. Effortlessly add and remove tasks, simplifying your daily routine. With a user-friendly search feature, finding specific tasks is a breeze. This application is designed to optimize your productivity and task management, ensuring you stay organized and in control of your to-do list, making it an essential tool for your daily life. </span>
            
          </div>
          <div className='bandTech'>
            <span>Javascript</span>
              <span>html</span>
              <span>css</span>
              
              <FontAwesomeIcon className='ico' icon={faGithub} style={{ color: '#ffd460' }} />
              <FontAwesomeIcon className='ico' icon={faChevronCircleRight} style={{color: "#ffd460",}} />
            </div>
            </div>
         
        </div>
      )}

{showModal5 && (
        <div className='modal'>
          <button className='modal-close' onClick={closeModal5}>
            &times; {/* "x" symbol for the close button */}
          </button>
          <span className='modalHead'>Whack a Mole</span>

          <div className='modalar'>
          <div className='modal-content'>
          <iframe src="https://yashnetz.netlify.app/pages/whack"></iframe>
            <span>JavaScript game—Whack-a-Mole. This thrilling game offers three modes: slow, medium, and fast, catering to various skill levels. Players can also choose their preferred game duration in seconds, adding a personalized challenge. Whack-a-Mole provides a dynamic gaming experience that's both fun and customizable, promising hours of excitement for players of all ages, while sharpening reflexes and hand-eye coordination. </span>
            
          </div>
          <div className='bandTech'>
            <span>Javascript</span>
              <span>html</span>
              <span>css</span>
              
              <FontAwesomeIcon className='ico' icon={faGithub} style={{ color: '#ffd460' }} />
              <FontAwesomeIcon className='ico' icon={faChevronCircleRight} style={{color: "#ffd460",}} />
            </div>
            </div>
         
        </div>
      )}

{showModal6 && (
        <div className='modal'>
          <button className='modal-close' onClick={closeModal6}>
            &times; {/* "x" symbol for the close button */}
          </button>
          <span className='modalHead'>Spending Tracker</span>

          <div className='modalar'>
          <div className='modal-content'>
          <iframe src="https://yashnetz.netlify.app/pages/spend.html"></iframe>
            <span>
Discover a robust JavaScript spending tracker application. Define financial goals and input expenses and income effortlessly. Keep a keen eye on your spending habits while monitoring your financial health. With the ability to set income targets and a detailed expense tracking system, you'll gain valuable insights into your finances. Easily calculate your total balance and take control of your financial future with this comprehensive and user-friendly tool. </span>
            
          </div>
          <div className='bandTech'>
            <span>Javascript</span>
              <span>html</span>
              <span>css</span>
              
              <FontAwesomeIcon className='ico' icon={faGithub} style={{ color: '#ffd460' }} />
              <FontAwesomeIcon className='ico' icon={faChevronCircleRight} style={{color: "#ffd460",}} />
            </div>
            </div>
         
        </div>
      )}

{showModal7 && (
        <div className='modal'>
          <button className='modal-close' onClick={closeModal7}>
            &times; {/* "x" symbol for the close button */}
          </button>
          <span className='modalHead'>Guess it</span>

          <div className='modalar'>
          <div className='modal-content'>
          <iframe src="https://yashnetz.netlify.app/pages/magic"></iframe>
            <span>Immerse yourself in a captivating JavaScript application that enhances your logo recognition skills. Begin by memorizing logos displayed on your screen. The app will then present questions about these logos, testing your memory and knowledge. As you respond, the app uses its algorithms to guess the logo based on your answers. It's an engaging way to challenge yourself and improve your logo recognition abilities while having fun. </span>
            
          </div>
          <div className='bandTech'>
            <span>Javascript</span>
              <span>html</span>
              <span>css</span>
              
              <FontAwesomeIcon className='ico' icon={faGithub} style={{ color: '#ffd460' }} />
              <FontAwesomeIcon className='ico' icon={faChevronCircleRight} style={{color: "#ffd460",}} />
            </div>
            </div>
         
        </div>
      )}

{showModal8 && (
        <div className='modal'>
          <button className='modal-close' onClick={closeModal8}>
            &times; {/* "x" symbol for the close button */}
          </button>
          <span className='modalHead'>Js bank</span>

          <div className='modalar'>
          <div className='modal-content'>
          <iframe src="https://yashnetz.netlify.app/pages/bankpage.html"></iframe>
            <span>
Engage with a comprehensive JavaScript banking replica that faithfully replicates real-world financial transactions. This user-friendly application allows you to seamlessly add funds to your account and make deposits, while continually monitoring your overall balance. What sets it apart is its ability to provide a curated list of potential purchases, tailored to what you can afford according to your current balance. It's a powerful financial tool that promotes responsible spending and financial planning. </span>
            
          </div>
          <div className='bandTech'>
            <span>Javascript</span>
              <span>html</span>
              <span>css</span>
              
              <FontAwesomeIcon className='ico' icon={faGithub} style={{ color: '#ffd460' }} />
              <FontAwesomeIcon className='ico' icon={faChevronCircleRight} style={{color: "#ffd460",}} />
            </div>
            </div>
         
        </div>
      )}

      
       
      </div>
    </div>
  );
}

export default Projects;
