import React, { useEffect, useRef  } from 'react';
import Typed from 'typed.js';
import pdf from './resume.pdf'



import './intro.scss';




const Intro = () => {
  const el = useRef(null);
  const handleDownload = (filePath) => {
    // Create an anchor element
    const anchor = document.createElement("a");
    // Set the href attribute to the file path
    anchor.href = filePath;
    // Set the download attribute to force download
    anchor.setAttribute("download", "");
    // Programmatically click the anchor element to trigger download
    anchor.click();
  };
  



  useEffect(() => {
    const options = {
      strings: ['> Npm start', 'Hello', 'hola', 'Namaste', 'Olá', 'nǐ hǎo 你好', 'Hallo', 'Bonjour', 'Konnichiwa', 'Guten tag', '👋' ],
      typeSpeed: 50,
      backSpeed: 100,
      loop: true,
    };

    


   
   

    const typed = new Typed(el.current, options);

  
  

    return () => {  // ComponentWillUnmount in Class Component
      typed.destroy();
    };
  }, []); // Empty array means this hook will only run once when the component mounts.

  return (
    <section className='intro' id='ripple'>
        <div className='introTexts'>
        <span ref={el} className="auto-type"></span>
        <span className='introText'>,I am</span>
        </div>
        <span className='name'>Yash Ahire.</span>
        <span className='introtwo'>iDevLoop</span>
        <span className='info'>
            
I'm a full-stack engineer with 4+ Years experience and a knack for not only building but occasionally designing web applications that prioritize accessibility and user-centric design. Completed Master's in Computer Science at the University of North Carolina at Charlotte. Worked extensively across <span className='introUniversity'>Healthcare,Finance</span> and <span className='introUniversity'>Construction</span> Domains
        </span>
        <button className='download' onClick={() => handleDownload(pdf)}>
  <i className="fas fa-download desktopMenuImg"></i>
  Resume
</button>


   


    </section>



  );
}

export default Intro;
