import React, { useState , useEffect } from 'react'
import './skills.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faJs } from '@fortawesome/free-brands-svg-icons';
import { faPython } from '@fortawesome/free-brands-svg-icons';
import { faDev } from '@fortawesome/free-brands-svg-icons';
import {faHtml5} from '@fortawesome/free-brands-svg-icons';
import {faHashnode} from '@fortawesome/free-brands-svg-icons';
import {faReact} from '@fortawesome/free-brands-svg-icons';
import {faNodeJs} from '@fortawesome/free-brands-svg-icons';
import {jqu} from '@fortawesome/free-brands-svg-icons';
import {faDocker} from '@fortawesome/free-brands-svg-icons';
import {faFigma} from '@fortawesome/free-brands-svg-icons';
import {faDatabase} from '@fortawesome/free-solid-svg-icons';
import {faGit} from '@fortawesome/free-brands-svg-icons';
import {faGoogle} from '@fortawesome/free-brands-svg-icons';
import {faMicrosoft} from '@fortawesome/free-brands-svg-icons';
import {faJira} from '@fortawesome/free-brands-svg-icons';
import {faUnity} from '@fortawesome/free-brands-svg-icons';
import {faPhp} from '@fortawesome/free-brands-svg-icons';

const Skills = () => {
    
    const [hoveredSkill, setHoveredSkill] = useState(null);
    const [hoveredSkill1, setHoveredSkill1] = useState(null);

    const handleHover = (skill) => {
      
      setHoveredSkill(skill);
    }

   
  
    const handleMouseLeave = () => {
      setHoveredSkill('javascript');
     

      
    }

    useEffect(() => {
        // This code will run when the component is loaded
        handleHover('javascript');
      }, []); // The empty array [] means it runs only once on component mount

    // onMouseEnter={() => handleHover('sql')} 


  return (
    <div className="skillsMain" >
        <div className='shead'>Skills<div className='eLine ski'></div></div>

       
        <div className='skillContent'>
      
      <div className='skillInfo'>
     
       
    
            <div className={`${hoveredSkill === 'javascript' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faJs} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>JavaScript</span>
                    <span className='skillInfoDesc'>JavaScript is my first language, and I've completed over 100 mini-projects with it. It's my preferred choice for web development due to its flexibility, expressive nature, and strong community support, making it valuable for both individual and team projects. The type system in Js is also a great asset for catching bugs early. </span>
                    <div className="progress" id='jsp'></div>
            
        
            </div>


            <div className={`${hoveredSkill === 'python' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faPython} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Python</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='psp'></div>            
            </div>

            <div className={`${hoveredSkill === 'sql' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faDev} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>SQL</span>
                    <span className='skillInfoDesc'>SQL plays a significant role in the database ecosystem. I've had coursework related to SQL in both my undergraduate and master's degrees. Furthermore, I have applied SQL extensively in numerous full-stack projects. </span>
                    <div className="progress" id='sqs'></div>            
            </div>


            <div className={`${hoveredSkill === 'html' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faHtml5} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Html/Css</span>
                    <span className='skillInfoDesc'>HTML was my initial programming language, and I've been using it for 8 years to create websites and web applications. I'm experienced with HTML5 APIs like Canvas, WebSockets, and Web Workers.  expertise, encompassing the CSS box model, flexbox, grid, and responsive design. </span>
                    <div className="progress" id='hts'></div>            
            </div>

            <div className={`${hoveredSkill === 'csharp' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faHashnode} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>c#</span>
                    <span className='skillInfoDesc'>While I may not have extensive expertise in C#, I have applied it effectively in Unity3D for both 2D and 3D game development. Additionally, I've utilized C# alongside Unity for various Python projects. </span>
                    <div className="progress" id='cts'></div>            
            </div>


            <div className={`${hoveredSkill === 'react' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faReact} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>ReactJs</span>
                    <span className='skillInfoDesc'>I'm highly experienced with React.js, and I used it to create this portfolio site and my recent project, the Live Score API etc. React is my preferred choice for building user interfaces, and I'm well-versed in React Hooks, Context, Redux, and other related technologies. </span>
                    <div className="progress" id='rjs'></div>            
            </div>

            <div className={`${hoveredSkill === 'node' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faNodeJs} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>NodeJs</span>
                    <span className='skillInfoDesc'>

I possess substantial Node.js expertise gained through professional, personal, and academic projects. I've leveraged Node.js to develop REST APIs, web applications, and have hands-on experience with popular frameworks like Express. </span>
                    <div className="progress" id='nd'></div>            
            </div>

            <div className={`${hoveredSkill === 'jq' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faDev} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>JQuery</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'doc' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faDocker} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Docker</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'fig' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faFigma} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Figma</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'rest' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faDev} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Rest</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'ora' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faDatabase} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Oracle</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'mon' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faDatabase} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>MongoDB</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'pow' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faDatabase} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>PowerBI</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'tab' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faDev} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Tableau</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'git' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faGit} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Git</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'gog' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faGoogle} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Google analytics</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'azu' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faMicrosoft} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Azure</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'ml' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faDev} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Machine Learning</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'jso' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faDev} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Json</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'php' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faPhp} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>PHP</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'jir' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faJira} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Jira</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'uni' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faUnity} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Unity</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'pg' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faUnity} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Unity</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'aws' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faUnity} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Unity</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'gq' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faUnity} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Unity</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>

            <div className={`${hoveredSkill === 'jv' ? 'active' : 'disp'}`}>
                    <FontAwesomeIcon className='icon' icon={faUnity} style={{ color: '#ffd460' }} />
                    <span className='skillInfoHead'>Unity</span>
                    <span className='skillInfoDesc'>Python is my preferred language for projects involving data science and machine learning. I have experience with NumPy, Pandas, Matplotlib, Scikit-Learn and PyTorch. In the past I have also used Python to build Flask applications with GraphQL support using Ariadne </span>
                    <div className="progress" id='cts'></div>            
            </div>
            
            

            
            
        </div>
        <div className='skillsList'>
           
            <div className='listOne'>
                
                    <span className={` ${hoveredSkill !== `javascript`  ? 'a' : 'js'}`} onMouseEnter={() => handleHover('javascript')} >Javascript</span>
                    <span className={` ${hoveredSkill !== `python`  ? 'a' : 'py'}`} onMouseEnter={() => handleHover('python')} >Python</span>
                    <span className={` ${hoveredSkill !== `sql`  ? 'a' : 'sq'}`} onMouseEnter={() => handleHover('sql')} >SQl</span>
                    <span className={` ${hoveredSkill !== `html`  ? 'a' : 'ht'}`} onMouseEnter={() => handleHover('html')} >Html/Css</span>
                    <span className={` ${hoveredSkill !== `csharp`  ? 'a' : 'cs'}`} onMouseEnter={() => handleHover('csharp')}  >c#</span>
                    </div>
            <div className='listTwo'>
                            <span className={` ${hoveredSkill !== `react`  ? 'b' : 'rj'}`} onMouseEnter={() => handleHover('react')} >ReactJs</span>
                        <span className={` ${hoveredSkill !== `node`  ? 'b' : 'no'}`} onMouseEnter={() => handleHover('node')} >NodeJs</span>
                        <span className={` ${hoveredSkill !== `jq`  ? 'b' : 'jq'}`} onMouseEnter={() => handleHover('jq')} >JQuery</span>

                        <span className={` ${hoveredSkill !== `docker`  ? 'b' : 'dc'}`} onMouseEnter={() => handleHover('doc')} >Docker</span>
            </div>

            <div className='listThree'>
                        <span className={` ${hoveredSkill !== `fig`  ? 'c' : 'js'}`} onMouseEnter={() => handleHover('fig')} >Figma</span>
                    <span className={` ${hoveredSkill !== `rest`  ? 'c' : 're'}`} onMouseEnter={() => handleHover('rest')} >RestAPI</span>
                    <span className={` ${hoveredSkill !== `ora`  ? 'c' : 'or'}`} onMouseEnter={() => handleHover('ora')} >oracle</span>
                    <span className={` ${hoveredSkill !== `mon`  ? 'c' : 'js'}`} onMouseEnter={() => handleHover('mon')} >mongoDB</span>
                    <span className={` ${hoveredSkill !== `pow`  ? 'c' : 'js'}`} onMouseEnter={() => handleHover('pow')} >Powerbi</span>
            </div>
            <div className='listFour'>
                 <span className={` ${hoveredSkill !== `tab`  ? 'd' : 'js'}`} onMouseEnter={() => handleHover('tab')} >tableau</span>
          <span className={` ${hoveredSkill !== `git`  ? 'd' : 'js'}`} onMouseEnter={() => handleHover('git')} >Git</span>
          <span className={` ${hoveredSkill !== `gog`  ? 'd' : 'js'}`} onMouseEnter={() => handleHover('gog')} >Google analytic</span>
          <span className={` ${hoveredSkill !== `azu`  ? 'd' : 'js'}`} onMouseEnter={() => handleHover('azu')} >Azure</span></div>

            <div className='listFive'>
            <span className={` ${hoveredSkill !== `ml`  ? 'e' : 'js'}`} onMouseEnter={() => handleHover('ml')} >Machine learning</span>
          <span className={` ${hoveredSkill !== `jso`  ? 'e' : 'js'}`} onMouseEnter={() => handleHover('jso')} >Json</span>
          <span className={` ${hoveredSkill !== `php`  ? 'e' : 'js'}`} onMouseEnter={() => handleHover('php')} >PHP</span>
          <span className={` ${hoveredSkill !== `jir`  ? 'e' : 'js'}`} onMouseEnter={() => handleHover('jir')} >Jira</span>
          <span className={` ${hoveredSkill !== `uni`  ? 'e' : 'js'}`} onMouseEnter={() => handleHover('uni')} >Unity3D</span>
            </div>
            <div className='listfour'>
            <span className={` ${hoveredSkill !== `pg`  ? 'f' : 'js'}`} onMouseEnter={() => handleHover('pg')} >PostGRES</span>
          <span className={` ${hoveredSkill !== `aws`  ? 'f' : 'js'}`} onMouseEnter={() => handleHover('aws')} >AWS</span>
          <span className={` ${hoveredSkill !== `gq`  ? 'f' : 'js'}`} onMouseEnter={() => handleHover('gq')} >GraphQL</span>
          <span className={` ${hoveredSkill !== `jv`  ? 'f' : 'js'}`} onMouseEnter={() => handleHover('jv')} >Java</span>
          
            </div>
           
          
        
         
         



 
        </div>
     
      </div>
    </div>
  )
}

export default Skills
