import React, { useEffect , useState } from 'react';
import './education.css';
import unc from '../../media/uncc.webp';
import sinhgad from '../../media/sinhgad.png';
import $ from 'jquery'; // Import jQuerynpm
import 'tilt.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';


const Education = () => {

  


  useEffect(() => {
    // JavaScript code for cursor animation
    
   
    // Tilt effect using jQuery
  

    $(".cardEdu").tilt({
      maxTilt: 15,
      perspective: 1500,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      speed: 500,
      glare: false,
      maxGlare: 0.2,
      scale: 1.01
    });

  }, []);

  return (
    <div className='EduMain'>
      <div className='headEdu'>Education<div className='eLine edu'></div></div>
      <div className='featurededu'>
        <div className='EduFirst'>
    
          <div className="cardEdu">
            <img className='imgOneEdu' src={unc} alt='Project Image' />
          </div>
          <div className='EduFirstInfo'>
            <div className='infoContentEdu'>
              <span className='featuredHeadEdu'>Masters of Science : Computer Science</span>
              <span className='eduHead'>University of North Carolina</span>
              <span className='infoContentChildEdu'>   <span>Database systems</span>
              <span>Data Structures and Algorithms</span>
              <span>Software Systems Design and Implementation</span>
              <span>Applied Machine Learning</span>
              <span>Natural Language Processing</span>
              <span>Visual Analytics</span>
              <span>Network Based Application Development</span>
            

</span>
            </div>
            
            <div className='techEdu'>
              
              <span><FontAwesomeIcon className='eduIco' icon={faLocationDot} style={{color: "#6385bf",}} />Charlotte</span>
              <span>2022 - 2024</span>
             


            </div>
            
          </div>
        </div>


        <div className='EduFirst'>
          <div className="bg__gradient"></div>
          <div className="cardEdu">
            <img className='imgOneEdu' src={sinhgad} alt='Project Image' />
          </div>
          <div className='EduFirstInfo'>
            <div className='infoContentEdu'>
              <span className='featuredHeadEdu'>Bachelors of Engineering : Computer Engineering</span>
              <span className='eduHead'>Sinhgad Institutes : Skncoe</span>
              <span className='infoContentChildEdu'> <span>Discrete Mathematics</span>
              <span>Object Oriented Programming</span>
              <span>Software Modelling and Design</span>
              <span>Cloud Computing</span>
              <span>Data Analytics</span>
              <span>Theory of Computation</span>
              <span>Computer Organization and Architecture</span>
</span>
            </div>
            
            <div className='techEdu'>
              
              <span> <FontAwesomeIcon className='eduIco' icon={faLocationDot} style={{color: "#6385bf",}} /> Pune</span>
              <span>2015-2019</span>
             


            </div>
            
          </div>
        </div>



        



        
      </div>
    
     
    </div>
  );
}

export default Education;
